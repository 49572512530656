import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  padding: 0.25rem 0.5rem;
  border-radius: 14px;
  background: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.yellow3 : '#F2F4F5') : 'none')};
  color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3)};
  font-size: 0.825rem;
  font-weight: 400;
`

const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  border-radius: 16px;
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.primary5 : '#F2F4F5')};
  display: flex;
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 0;
  background-color: transparent;
`

export interface ToggleProps {
  id?: string
  isActive: boolean
  toggle: () => void
  onText?: string | undefined
  offText?: string | undefined
}

export default function Toggle({ id, isActive, toggle, onText, offText }: ToggleProps) {
  const { t } = useTranslation()
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      <ToggleElement isActive={isActive} isOnSwitch={true}>
        {onText ? onText : t('on')}
      </ToggleElement>
      <ToggleElement isActive={!isActive} isOnSwitch={false}>
        {offText ? offText : t('off')}
      </ToggleElement>
    </StyledToggle>
  )
}
