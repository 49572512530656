/* eslint-disable */
import { Currency, ETHER, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import EthereumLogo from '../../assets/images/cra-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

// TODO 这个配置是token 选择时  上面的快捷选择币的图标
const getTokenLogoURL = (address: string) => {
  let url;
  switch(address){
    // WCRA
      case '0x25F031051cFb70af0F519922898E75737aCA84fF':
        url = 'https://cra-ico.oss-cn-hongkong.aliyuncs.com/CRA.png';
        break;
        // USDT
      case '0x0f4F1Ce14Ff3094B7cAD5dC6d6508B5962a09c3F':
        url = 'https://cra-ico.oss-cn-hongkong.aliyuncs.com/USDT.png';
        break;
        // PECO
      case '0xcbe3721847f34f6d3257312A2afbFD3616330eb4':
        url = 'https://cra-ico.oss-cn-hongkong.aliyuncs.com/PECO.png';
        break;
      default:
        url = '';
  }
  return url;
  // return `https://faucet.crascan.com/logo.png`
}


const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
